import React, { FC, useEffect, useState } from 'react';
import { Label } from '@oberoninternal/travelbase-ds/components/primitive/Label';
import { TextInputField } from '@oberoninternal/travelbase-ds/components/form/TextInput';
import LabelBox from '../molecules/LabelBox';
import { StepperField } from '@oberoninternal/travelbase-ds/components/form/Stepper';
import { RadioField } from '@oberoninternal/travelbase-ds/components/form/Radio';
import styled from 'styled-components/macro';
import Button from '@oberoninternal/travelbase-ds/components/action/Button';
import getLogoForBrand from '@oberoninternal/travelbase-ds/utils/getLogoForBrand';
import { Box, Flex } from '@rebass/grid';
import FieldSet from '../atoms/FieldSet';
import Logo from '@oberoninternal/travelbase-ds/components/figure/LogoText';
import { generatePath } from 'react-router-dom';
import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import brandConfig from '../../constants/brandConfig';
import { camperContactValidation } from '../../utils/validations/registrationValidation';

import {
    AccommodationRegistrationInput,
    AccommodationTypeEnum,
    AddressInput,
    ContactInfoInput,
    RentalUnitRegistrationInput,
    RentalUnitTypeEnum,
    useRegisterNewPartnerMutation,
} from '../../generated/graphql';
import SelectInput, { OptionType } from '@oberoninternal/travelbase-ds/components/form/SelectInput';
import Checkbox from '@oberoninternal/travelbase-ds/components/form/Checkbox';
import { Container as NavigationContainer, LogoLink, Seperator } from '../organisms/Navigation';
import FieldHeading from '../molecules/FieldHeading';
import ArrayTextInput from '../molecules/ArrayTextInput';
import { FieldArray, Form, Formik, FormikProps, getIn, useFormikContext } from 'formik';
import { ApolloError } from '@apollo/client';
import Title from '@oberoninternal/travelbase-ds/components/primitive/Title';
import ContentWrapper from '../atoms/ContentWrapper';
import FieldSetHint from '../atoms/FieldSetHint';
import { defineMessages, FormattedMessage, IntlShape, useIntl } from 'react-intl';
import {
    defaultAccommodationTypeOptions,
    defaultRentalUnitTypesOptions,
} from '../../utils/validations/accomodationTypeOptions';

interface Values {
    accommodations: AccommodationRegistrationInput[];
    bic?: string;
    comment?: string;
    contact: ContactInfoInput;
    iban?: string;
    ibanName?: string;
    name: string;
    pmsName?: string;
    sameAsAccommodationAddress?: boolean;
    invoiceAddress?: AddressInput;
    invoiceEmail?: string;
    vatNumber?: string;
    subscriptionPackage?: string;
    invoiceEmails?: string[];
}

interface ArrayRentalInputProps {
    name: string;
    addDescription: string;
}

interface SelectedAccommodationsType extends OptionType {
    value: AccommodationTypeEnum | string;
    label: string;
    rentalUnitsType?: RentalUnitTypeEnum;
}

const { countryOptions } = brandConfig;

const getRentalUnitOptions = (intl: IntlShape) =>
    brandConfig.registrationForm?.rentalUnitOptions ?? defaultRentalUnitTypesOptions(intl);
const getAccommodationOptions = (intl: IntlShape) =>
    brandConfig.registrationForm?.accommodationOptions ?? defaultAccommodationTypeOptions(intl);

/**
 * This method is used to alter the accommodations object, and only used when
 * the user selects "Vakantiewoning" or "Boot" as the accommodation type.
 * The following logic takes place:
 *
 * - LOCATION is set as accommodation type
 * - Rental units is sent as an object, where maxAllotment is always 1, the name is
 *   equal to the Accommodation name and the type is ROOM or BOAT depending on the
 *   accommodation type
 *
 * @param accommodations
 * @param accommodationTypeOptions
 * @returns Modified accommodations object
 */
const getModifiedAccommodations = (
    accommodations: Array<AccommodationRegistrationInput>,
    accommodationTypeOptions: SelectedAccommodationsType[]
): AccommodationRegistrationInput[] => [
    ...accommodations.map(accommodation => ({
        ...accommodation,
        type: AccommodationTypeEnum.Location,
        rentalUnits: [
            {
                ...accommodation.rentalUnits[0],
                maxAllotment: 1,
                name: accommodation.name,
                type:
                    accommodationTypeOptions.find(accoOption => accoOption.value === accommodation.type)
                        ?.rentalUnitsType === RentalUnitTypeEnum.Boat
                        ? RentalUnitTypeEnum.Boat
                        : RentalUnitTypeEnum.Room,
            },
        ],
    })),
];

const Registration: FC = () => {
    const [mutate, { error }] = useRegisterNewPartnerMutation({});
    const [submitSuccessful, setSubmitSuccessful] = useState(false);
    const intl = useIntl();
    const accommodationOptions = getAccommodationOptions(intl);
    const rentalUnitOptions = getRentalUnitOptions(intl);

    const initialValues: Values = {
        accommodations: [
            {
                address: {
                    city: '',
                    number: '',
                    postalCode: '',
                    street: '',
                    countryCode: countryOptions.length === 1 ? countryOptions[0].value : '',
                },
                name: '',
                rentalUnits: [
                    {
                        maxAllotment: 1,
                        maxVehicleSize: 6, // You can set a default value here if needed
                        name: '',
                        type:
                            rentalUnitOptions.length === 1
                                ? (rentalUnitOptions[0].value as RentalUnitTypeEnum)
                                : RentalUnitTypeEnum.Room,
                    },
                ],
                publicUrl: '',
                starRating: 1,
                type:
                    accommodationOptions.length === 1
                        ? (accommodationOptions[0].value as AccommodationTypeEnum)
                        : AccommodationTypeEnum.Hotel,
            },
        ],
        sameAsAccommodationAddress: false,
        bic: '',
        comment: '',
        contact: {
            contactName: '',
            emails: [''],
            phoneNumbers: [],
        },
        invoiceEmails: [''],
        iban: '',
        ibanName: '',
        name: '',
        pmsName: '',
        invoiceAddress: {
            city: '',
            number: '',
            postalCode: '',
            street: '',
            countryCode: '',
        },
        invoiceEmail: '',
        vatNumber: '',
    };

    return (
        <>
            <NavigationContainer>
                <LogoLink to={generatePath(`${process.env.PUBLIC_URL}/login`)}>
                    <Logo variant="dark" />
                </LogoLink>
            </NavigationContainer>
            {submitSuccessful ? (
                <ContentWrapper>
                    <Box mt={5}>
                        <Title variant="regular">
                            <FormattedMessage defaultMessage="Bedankt voor je aanmelding!" />
                        </Title>
                        <p>
                            <FormattedMessage defaultMessage="Onze medewerkers gaan de ingevoerde gegevens zo snel mogelijk controleren en verwerken. Je ontvangt binnen enkele werkdagen een reactie van ons." />
                        </p>
                    </Box>
                </ContentWrapper>
            ) : (
                <ContentWrapper variant="info">
                    <LogoContainer>{getLogoForBrand()}</LogoContainer>

                    <Box mt={5}>
                        <Title variant="regular">
                            <FormattedMessage defaultMessage="Aanmelden als partner" />
                        </Title>
                        <p>
                            <FormattedMessage defaultMessage="Via dit formulier kun je je aanmelden als partner zodat jouw accommodatie via ons gevonden en geboekt kan worden. Zodra we je aanmelding hebben verwerkt, kan je zelf via onze partnerapplicatie aanvullende informatie, afbeeldingen, prijzen, etc. invoeren." />
                            <br />
                            <br />
                        </p>
                        <Body variant="small">
                            <FormattedMessage defaultMessage="Dit formulier is enkel bedoeld voor nieuwe partners. Heb je al toegang tot de partnerapplicatie en wil je een nieuwe accommodatie toevoegen? Neem dan contact op met ons." />
                        </Body>
                        <Formik<Values>
                            validationSchema={camperContactValidation(intl)}
                            initialValues={initialValues}
                            validateOnChange={false}
                            onSubmit={async (
                                {
                                    accommodations,
                                    bic,
                                    comment,
                                    contact,
                                    iban,
                                    ibanName,
                                    name,
                                    pmsName,
                                    subscriptionPackage,
                                    invoiceAddress,
                                    sameAsAccommodationAddress,
                                    vatNumber,
                                    invoiceEmails,
                                },
                                { setSubmitting }
                            ) => {
                                // Adjust accommodations input if accommodation type is "Vakantiewoning" or "Boot"
                                const accommodationsRegistration =
                                    accommodations.some(
                                        accommodation => accommodation.type === AccommodationTypeEnum.Location
                                    ) && rentalUnitOptions.length > 1
                                        ? getModifiedAccommodations(accommodations, accommodationOptions)
                                        : accommodations;

                                // Only pass star rating if accommodations type is Hotel
                                accommodations
                                    .filter(accommodation => accommodation.type !== AccommodationTypeEnum.Hotel)
                                    .forEach(accommodation => {
                                        // eslint-disable-next-line no-param-reassign
                                        delete accommodation.starRating;
                                    });

                                const formattedComment = brandConfig.registrationForm?.hasPackageOptions
                                    ? `Pakket: ${subscriptionPackage}\n opmerking: ${comment}`
                                    : comment;
                                try {
                                    await mutate({
                                        variables: {
                                            input: {
                                                accommodations: accommodationsRegistration,
                                                bic,
                                                comment: formattedComment,
                                                contact,
                                                iban,
                                                ibanName,
                                                name,
                                                pmsName,
                                                invoiceAddress: sameAsAccommodationAddress
                                                    ? accommodations[0].address
                                                    : invoiceAddress,
                                                invoiceEmails,
                                                vatNumber,
                                            },
                                        },
                                    });
                                } finally {
                                    if (!error) {
                                        setSubmitSuccessful(true);
                                    }
                                    setSubmitting(false);
                                }
                            }}
                        >
                            {bag => <RegistrationForm {...bag} mutationError={error} />}
                        </Formik>
                    </Box>
                </ContentWrapper>
            )}
        </>
    );
};

const RegistrationForm: FC<FormikProps<Values> & { mutationError?: ApolloError }> = ({
    isSubmitting,
    setFieldError,
    mutationError,
}) => {
    const { values, isValid } = useFormikContext<Values>();
    useEffect(() => {
        if (!isValid && isSubmitting) {
            setTimeout(() => {
                document
                    .querySelectorAll('[data-cy="errorMessage"]')[0]
                    ?.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }, 100);
        }
    }, [values, isValid, isSubmitting]);
    useEffect(() => {
        if (mutationError) {
            const graphqlError = mutationError?.graphQLErrors[0];

            setFieldError('comment', graphqlError ? graphqlError.message : mutationError.message);
        }
    }, [mutationError, setFieldError]);

    return (
        <Form>
            <AccommodationSection />
            <PartnerSection />
            <FinancialSection />

            {brandConfig.registrationForm?.hasPackageOptions && <PackageSection />}

            <ExtraInformation />
            <RegistrationButton submitting={isSubmitting} disabled={isSubmitting} type="submit">
                <FormattedMessage defaultMessage="Aanmelden" />
            </RegistrationButton>
        </Form>
    );
};

const Accommodation: FC<{ index: number }> = ({ index }) => {
    const { values } = useFormikContext<Values>();
    const intl = useIntl();

    const { formatMessage } = intl;
    const accommodationOptions = getAccommodationOptions(intl);

    return (
        <StyledAccommodationContainer>
            <ConditionalHiddenFieldSet hidden={accommodationOptions.length <= 1}>
                <LabelBox>
                    <Label htmlFor={`accommodations.${index}.type`}>
                        {/* Type */}
                        <FormattedMessage defaultMessage="Type" />
                    </Label>
                </LabelBox>
                <Box width={1}>
                    <SelectInput
                        id={`accommodations.${index}.type`}
                        name={`accommodations.${index}.type`}
                        placeholder={formatMessage({ defaultMessage: 'Selecteer accommodatie type' })}
                        options={accommodationOptions}
                        value={accommodationOptions.length === 1 ? accommodationOptions[0] : null}
                    />
                </Box>
            </ConditionalHiddenFieldSet>
            <FieldSet>
                <LabelBox>
                    <Label htmlFor={`accommodations.${index}.name`}>
                        <FormattedMessage defaultMessage="Naam" />
                        <RequiredStar />
                    </Label>
                </LabelBox>
                <Box width={1}>
                    <TextInputField id={`accommodations.${index}.name`} name={`accommodations.${index}.name`} />
                </Box>
            </FieldSet>
            {values.accommodations[index].type === AccommodationTypeEnum.Hotel && (
                <Box mb={4}>
                    <FieldSet>
                        <LabelBox>
                            <Label>
                                <FormattedMessage defaultMessage="Sterren" />
                            </Label>
                        </LabelBox>
                        <StepperField minimum={1} maximum={5} name={`accommodations.${index}.starRating`} />
                    </FieldSet>
                </Box>
            )}
            <FieldSet>
                <Box width={1}>
                    <FieldSet>
                        <LabelBox>
                            <Label htmlFor={`accommodations.${index}.address.street`}>
                                <FormattedMessage defaultMessage="Adres" />
                                <RequiredStar />
                            </Label>
                        </LabelBox>
                        <Flex flex={1} flexDirection="column">
                            <Flex flex={1} width={1} flexDirection={['column', 'row']}>
                                <Box flex={1} pr={[null, 4]}>
                                    <TextInputField
                                        id={`accommodations.${index}.address.street`}
                                        placeholder={formatMessage({ defaultMessage: 'Straatnaam' })}
                                        name={`accommodations.${index}.address.street`}
                                    />
                                </Box>
                                <Box mt={[4, 0]} width={[1, '20rem']}>
                                    <TextInputField
                                        maxLength={20}
                                        placeholder={formatMessage({ defaultMessage: 'Huisnummer' })}
                                        name={`accommodations.${index}.address.number`}
                                    />
                                </Box>
                            </Flex>
                            <Flex mt="2rem" flex={1} width={1} flexDirection={['column', 'row']}>
                                <Box width={[1, '20rem']}>
                                    <TextInputField
                                        maxLength={20}
                                        id={`accommodations.${index}.address.postalCode`}
                                        placeholder={formatMessage({ defaultMessage: 'Postcode' })}
                                        name={`accommodations.${index}.address.postalCode`}
                                    />
                                </Box>
                                <Box flex={1} mt={[4, 0]} pl={[null, 4]}>
                                    <TextInputField
                                        id={`accommodations.${index}.address.city`}
                                        placeholder={formatMessage({ defaultMessage: 'Plaats' })}
                                        name={`accommodations.${index}.address.city`}
                                    />
                                </Box>
                            </Flex>
                            {countryOptions.length > 1 && (
                                <Flex mt="2rem" flex={1} width={1} flexDirection={['column', 'row']}>
                                    <Box width={[1, 0.5]} style={{ maxWidth: '40rem' }}>
                                        <SelectInput
                                            id={`accommodations.${index}.address.countryCode`}
                                            name={`accommodations.${index}.address.countryCode`}
                                            isSearchable
                                            placeholder={formatMessage({ defaultMessage: 'Selecteer land' })}
                                            options={countryOptions}
                                        />
                                    </Box>
                                </Flex>
                            )}
                        </Flex>
                    </FieldSet>
                    {(values.accommodations[index].type !== AccommodationTypeEnum.Location ||
                        accommodationOptions.length === 1) && <RentalUnitsSection index={index} />}
                </Box>
            </FieldSet>
        </StyledAccommodationContainer>
    );
};

const brandName = brandConfig.brandName ?? 'ons';

const AccommodationSection: FC = () => {
    const { values } = useFormikContext<Values>();
    const intl = useIntl();
    const { formatMessage } = intl;

    const accommodationOptions = getAccommodationOptions(intl);
    const rentalUnitOptions = getRentalUnitOptions(intl);

    return (
        <FieldArray name="accommodations">
            {({ push, remove }) => {
                const array = getIn(values, 'accommodations');
                return (
                    <>
                        <FieldHeading title={formatMessage({ defaultMessage: 'Accommodatie' })}>
                            <FormattedMessage
                                defaultMessage="Gegevens over de accommodatie die je via {brandName} wil gaan verhuren."
                                values={{ brandName }}
                            />
                        </FieldHeading>
                        <Accommodation index={0} />
                        {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                        {array.slice(1).map((_: any, i: any) => {
                            const index = i + 1;
                            return (
                                <React.Fragment key={index}>
                                    <Accommodation index={index} />

                                    <Button
                                        style={{
                                            marginBottom: '8px',
                                        }}
                                        variant="outline"
                                        onClick={() => remove(index)}
                                    >
                                        {/* Accommodatie verwijderen */}
                                        <FormattedMessage defaultMessage="Accommodatie verwijderen" />
                                    </Button>
                                </React.Fragment>
                            );
                        })}

                        <Button
                            variant="outline"
                            onClick={() =>
                                push({
                                    address: {
                                        city: '',
                                        number: '',
                                        postalCode: '',
                                        street: '',
                                        countryCode: countryOptions.length === 1 ? countryOptions[0].value : '',
                                    },
                                    name: '',
                                    rentalUnits: [
                                        {
                                            maxAllotment: 1,
                                            name: '',
                                            type:
                                                rentalUnitOptions.length === 1
                                                    ? rentalUnitOptions[0].value
                                                    : RentalUnitTypeEnum.Room,
                                            maxVehicleSize:
                                                rentalUnitOptions[0].value === RentalUnitTypeEnum.CamperSpot ||
                                                rentalUnitOptions[0].value === RentalUnitTypeEnum.CampingPitch
                                                    ? 6
                                                    : undefined,
                                        },
                                    ],
                                    starRating: 1,
                                    publicUrl: '',

                                    type:
                                        accommodationOptions.length === 1
                                            ? accommodationOptions[0].value
                                            : AccommodationTypeEnum.Hotel,
                                })
                            }
                        >
                            {array.length === 0 ? (
                                <FormattedMessage defaultMessage="Accommodatie toevoegen" />
                            ) : (
                                <FormattedMessage defaultMessage="Nog een accommodatie toevoegen" />
                            )}
                        </Button>
                    </>
                );
            }}
        </FieldArray>
    );
};

const PartnerSection = () => {
    const { formatMessage } = useIntl();
    return (
        <>
            <FieldHeading title={formatMessage({ defaultMessage: 'Contactgegevens' })}>
                <FormattedMessage defaultMessage="Op welke manier kunnen we je bereiken voor vragen en opmerkingen." />
            </FieldHeading>
            <FieldSet>
                <LabelBox>
                    <Label htmlFor="name">
                        <FormattedMessage defaultMessage="Bedrijfsnaam" />
                        <RequiredStar />
                    </Label>
                    <FieldSetHint>
                        <FormattedMessage defaultMessage="Registreer je je als particulier? Vul dan je eigen naam in." />
                    </FieldSetHint>
                </LabelBox>
                <Box width={1}>
                    <TextInputField id="name" name="name" />
                </Box>
            </FieldSet>
            <FieldSet>
                <LabelBox>
                    <Label htmlFor="contact.contactName">
                        <FormattedMessage defaultMessage="Naam contactpersoon" />
                        <RequiredStar />
                    </Label>
                </LabelBox>
                <Box width={1}>
                    <TextInputField
                        id="contact.contactName"
                        placeholder={formatMessage({ defaultMessage: 'Voor- en achternaam' })}
                        name="contact.contactName"
                    />
                </Box>
            </FieldSet>
            <FieldSet>
                <LabelBox>
                    <Label htmlFor="contact.phoneNumbers">
                        <FormattedMessage defaultMessage="Telefoonnummer" />
                        <RequiredStar />
                    </Label>
                    <FieldSetHint>
                        <FormattedMessage defaultMessage="Gebruik internationale notatie (+31612345678)." />
                    </FieldSetHint>
                </LabelBox>
                <Box width={1}>
                    <ArrayTextInput
                        placeholder=""
                        name="contact.phoneNumbers"
                        addDescription={formatMessage({ defaultMessage: 'Extra telefoonnummer' })}
                    />
                </Box>
            </FieldSet>
            <FieldSet>
                <LabelBox>
                    <Label htmlFor="contact.emails">
                        <FormattedMessage defaultMessage="E-mailadres" />
                        <RequiredStar />
                    </Label>
                </LabelBox>
                <Box width={1}>
                    <ArrayTextInput
                        placeholder=""
                        name="contact.emails"
                        addDescription={formatMessage({ defaultMessage: 'Extra e-mailadres' })}
                    />
                </Box>
            </FieldSet>
        </>
    );
};

const RentalUnitsSection: FC<{ index: number }> = ({ index }) => {
    const { formatMessage } = useIntl();
    return (
        <>
            <FieldHeading title={formatMessage({ defaultMessage: 'Verblijf' })}>
                <FormattedMessage defaultMessage="Voeg de verschillende soorten boekbaar verblijf toe" />
            </FieldHeading>

            <ArrayRentalInput
                name={`accommodations.${index}.rentalUnits`}
                addDescription={formatMessage({ defaultMessage: 'Nog een verblijf toevoegen' })}
            />
        </>
    );
};
const messages = defineMessages({
    [RentalUnitTypeEnum.CamperSpot]: {
        defaultMessage:
            'Maak onderscheid in de naam indien je verschillende verblijven aanbiedt. Denk aan; Grote plaats, Plek met schaduw, Harde ondergrond, etc.',
    },
    [RentalUnitTypeEnum.CampingPitch]: {
        defaultMessage:
            'Maak onderscheid in de naam indien je verschillende verblijven aanbiedt. Denk aan; Grote plaats, Plek met schaduw, Harde ondergrond, etc.',
    },
    [RentalUnitTypeEnum.Tent]: {
        defaultMessage:
            'Maak onderscheid in de naam indien je verschillende verblijven aanbiedt. Denk aan; Grote plaats, Plek met schaduw, Harde ondergrond, etc.',
    },
    [RentalUnitTypeEnum.Room]: {
        defaultMessage:
            'Maak onderscheid in de naam indien je verschillende kamers aanbiedt. Denk aan; Comfort, Kamer met zeezicht, Familiekamer, etc.',
    },
    [RentalUnitTypeEnum.Home]: {
        defaultMessage:
            'Maak onderscheid in de naam indien je verschillende type woningen aanbiedt. Denk aan; Chalet, Gelijksvloers, Aan het water, etc.',
    },
    [RentalUnitTypeEnum.Bed]: {
        defaultMessage:
            'Maak onderscheid in de naam indien je verschillende type bedden aanbiedt. Denk aan; Stapelbed, In grote slaapzaal, Op de slaapbank, etc.',
    },
    [RentalUnitTypeEnum.Boat]: {
        defaultMessage:
            'Maak onderscheid in de naam indien je verschillende boten aanbiedt. Gebruik bij voorkeur de naam van het schip.',
    },
});

const ArrayRentalInput: FC<ArrayRentalInputProps> = ({ name, addDescription }) => {
    const intl = useIntl();

    const { formatMessage } = intl;
    const rentalUnitOptions = getRentalUnitOptions(intl);

    return (
        <FieldArray name={name}>
            {({ push, remove, form: { values } }) => {
                const array: RentalUnitRegistrationInput[] = getIn(values, name);

                return (
                    <ArrayRentalInputContainer>
                        <FieldSet>
                            <LabelBox>
                                <Label htmlFor={`${name}.0.name`}>
                                    <FormattedMessage defaultMessage="Naam" />
                                    <RequiredStar />
                                </Label>
                                <FieldSetHint>
                                    {formatMessage(messages[array[0].type as RentalUnitTypeEnum])}
                                </FieldSetHint>
                            </LabelBox>
                            <Box width={1}>
                                <TextInputField
                                    id={`${name}.0.name`}
                                    placeholder={formatMessage({
                                        defaultMessage: 'De naam van dit specifieke object/type',
                                    })}
                                    name={`${name}.0.name`}
                                />
                            </Box>
                        </FieldSet>
                        <ConditionalHiddenFieldSet hidden={rentalUnitOptions.length <= 1}>
                            <LabelBox>
                                <Label htmlFor={`${name}.0.type`}>
                                    <FormattedMessage defaultMessage="Soort" />
                                </Label>
                            </LabelBox>
                            <Box width={1}>
                                <SelectInput
                                    name={`${name}.0.type`}
                                    placeholder={formatMessage({ defaultMessage: 'Wat voor object is dit?' })}
                                    options={rentalUnitOptions}
                                    disabled={rentalUnitOptions.length === 1}
                                    value={rentalUnitOptions.length === 1 ? rentalUnitOptions[0].value : undefined}
                                />
                            </Box>
                        </ConditionalHiddenFieldSet>

                        <FieldSet>
                            <LabelBox>
                                <Label>
                                    <FormattedMessage defaultMessage="Aantal" />
                                </Label>
                            </LabelBox>
                            <StepperField editable nullable minimum={1} name={`${name}.0.maxAllotment`} />
                        </FieldSet>

                        {(array[0].type === RentalUnitTypeEnum.CamperSpot ||
                            array[0].type === RentalUnitTypeEnum.CampingPitch) && (
                            <FieldSet>
                                <LabelBox>
                                    <Label htmlFor={`${name}.0.maxVehicleSize`}>
                                        <FormattedMessage defaultMessage="Maximale lengte voertuig" />
                                    </Label>
                                    <FieldSetHint>
                                        <FormattedMessage defaultMessage="Geef hier de maximaal toegestane lengte aan in meters." />
                                    </FieldSetHint>
                                </LabelBox>
                                <Box width={1}>
                                    <StepperField editable minimum={6} maximum={20} name={`${name}.0.maxVehicleSize`} />
                                </Box>
                            </FieldSet>
                        )}

                        {array?.slice(1)?.map((_, i) => {
                            const index = i + 1;
                            return (
                                <ArrayRentalInputContainer key={`${name}-${index}`} style={{ position: 'relative' }}>
                                    <Seperator />
                                    <ConditionalHiddenFieldSet hidden={rentalUnitOptions.length <= 1}>
                                        <LabelBox>
                                            <Label htmlFor={`${name}.${index}.type`}>
                                                <FormattedMessage defaultMessage="Soort" />
                                            </Label>
                                        </LabelBox>
                                        <Box width={1}>
                                            <SelectInput
                                                name={`${name}.${index}.type`}
                                                plaecholder={formatMessage({
                                                    defaultMessage: 'Wat voor object is dit?',
                                                })}
                                                options={rentalUnitOptions}
                                                disabled={rentalUnitOptions.length === 1}
                                                value={
                                                    rentalUnitOptions.length === 1
                                                        ? rentalUnitOptions[0].value
                                                        : undefined
                                                }
                                            />
                                        </Box>
                                    </ConditionalHiddenFieldSet>
                                    <FieldSet>
                                        <LabelBox>
                                            <Label htmlFor={`${name}.${index}.name`}>
                                                {/* Naam  */}
                                                <FormattedMessage defaultMessage="Naam" />
                                                <RequiredStar />
                                            </Label>
                                        </LabelBox>
                                        <Box width={1}>
                                            <TextInputField
                                                id={`${name}.${index}.name`}
                                                placeholder={formatMessage({
                                                    defaultMessage: 'De naam van dit specifieke object/type',
                                                })}
                                                name={`${name}.${index}.name`}
                                            />
                                        </Box>
                                    </FieldSet>
                                    <FieldSet>
                                        <LabelBox>
                                            <Label htmlFor={`${name}.${index}.maxAllotment`}>
                                                {/* Maximaal beschikbaar */}
                                                <FormattedMessage defaultMessage="Maximaal beschikbaar" />
                                            </Label>
                                        </LabelBox>
                                        <StepperField editable minimum={1} name={`${name}.${index}.maxAllotment`} />
                                    </FieldSet>

                                    {(array[index].type === RentalUnitTypeEnum.CamperSpot ||
                                        array[index].type === RentalUnitTypeEnum.CampingPitch) && (
                                        <FieldSet>
                                            <LabelBox>
                                                <Label htmlFor={`${name}.${index}.maxVehicleSize`}>
                                                    <FormattedMessage defaultMessage="Maximale lengte voertuig" />
                                                </Label>
                                                <FieldSetHint>
                                                    <FormattedMessage defaultMessage="Geef hier de maximaal toegestane lengte aan in meters." />
                                                </FieldSetHint>
                                            </LabelBox>
                                            <Box width={1}>
                                                <StepperField
                                                    editable
                                                    minimum={6}
                                                    maximum={20}
                                                    name={`${name}.${index}.maxVehicleSize`}
                                                />
                                            </Box>
                                        </FieldSet>
                                    )}

                                    <Button variant="outline" onClick={() => remove(index)}>
                                        <FormattedMessage defaultMessage="Verblijf verwijderen" />
                                    </Button>
                                </ArrayRentalInputContainer>
                            );
                        })}
                        <Flex>
                            <Button
                                variant="outline"
                                onClick={() =>
                                    push({
                                        type: rentalUnitOptions[0].value,
                                        name: '',
                                        maxAllotment: 1,
                                        maxVehicleSize:
                                            rentalUnitOptions[0].value === RentalUnitTypeEnum.CamperSpot ||
                                            rentalUnitOptions[0].value === RentalUnitTypeEnum.CampingPitch
                                                ? 6
                                                : undefined,
                                    })
                                }
                                disabled={array?.length === 0}
                            >
                                {addDescription}
                            </Button>
                        </Flex>
                    </ArrayRentalInputContainer>
                );
            }}
        </FieldArray>
    );
};

const FinancialSection = () => {
    const { values, setFieldValue } = useFormikContext<Values>();
    const { formatMessage } = useIntl();
    return (
        <>
            <FieldHeading title={formatMessage({ defaultMessage: 'Financiele gegevens' })} />
            <FieldSet>
                <LabelBox>
                    <Label htmlFor="pmsName">
                        <FormattedMessage defaultMessage="Naam PMS" />
                    </Label>
                    <FieldSetHint>
                        <FormattedMessage defaultMessage="Welk softwaresysteem gebruik je om je prijzen/beschikbaarheid/etc. in te beheren?" />
                    </FieldSetHint>
                </LabelBox>
                <Box width={1}>
                    <TextInputField id="pmsName" name="pmsName" />
                </Box>
            </FieldSet>
            <FieldSet>
                <LabelBox>
                    <Label htmlFor="iban">
                        <FormattedMessage defaultMessage="IBAN" />
                        <RequiredStar />
                    </Label>
                    <FieldSetHint>
                        <FormattedMessage defaultMessage="Voor het afhandelen van uitbetalingen hebben we bankgegevens nodig. " />
                    </FieldSetHint>
                </LabelBox>
                <Box width={1}>
                    <TextInputField
                        onChange={e => setFieldValue('iban', e.target.value.toUpperCase())}
                        id="iban"
                        autoCapitalize="characters"
                        name="iban"
                    />
                </Box>
            </FieldSet>
            <FieldSet>
                <LabelBox>
                    <Label htmlFor="ibanName">
                        <FormattedMessage defaultMessage="Tenaamstelling rekening" />
                        <RequiredStar />
                    </Label>
                </LabelBox>
                <Box width={1}>
                    <TextInputField id="ibanName" name="ibanName" />
                </Box>
            </FieldSet>
            <FieldSet>
                <LabelBox>
                    <Label htmlFor="bic">
                        <FormattedMessage defaultMessage="BIC" />
                    </Label>
                    <FieldSetHint>
                        <FormattedMessage defaultMessage="Invullen als rekening niet Nederlands is." />
                    </FieldSetHint>
                </LabelBox>
                <Box width={1}>
                    <TextInputField id="bic" name="bic" />
                </Box>
            </FieldSet>

            <FieldSet>
                <LabelBox>
                    <Label htmlFor="invoiceEmails">
                        <FormattedMessage defaultMessage="E-mailadres facturen" />
                        <RequiredStar />
                    </Label>
                </LabelBox>
                <Box width={1}>
                    <ArrayTextInput
                        name="invoiceEmails"
                        placeholder=""
                        addDescription={formatMessage({ defaultMessage: 'Extra e-mailadres' })}
                    />
                </Box>
            </FieldSet>

            <Box>
                <FieldSet>
                    <Box width={1}>
                        <FieldSet>
                            <LabelBox>
                                <Label htmlFor="sameAsAccommodationAddress">
                                    <FormattedMessage defaultMessage="Factuuradres" />
                                    <RequiredStar />
                                </Label>
                            </LabelBox>
                            <Flex flex={1} flexDirection="column">
                                <Flex>
                                    <Checkbox
                                        checked={values.sameAsAccommodationAddress}
                                        id="sameAsAccommodationAddress"
                                        name="sameAsAccommodationAddress"
                                        onChange={e => {
                                            setFieldValue('sameAsAccommodationAddress', e.target.checked).then();
                                        }}
                                    />
                                    <Label htmlFor="sameAsAccommodationAddress">
                                        <Box mr={2}>
                                            <FormattedMessage defaultMessage="Factuuradres is gelijk aan accommodatieadres" />
                                        </Box>
                                    </Label>
                                </Flex>

                                {!values.sameAsAccommodationAddress && (
                                    <>
                                        <Flex mt="2rem" flex={1} width={1} flexDirection={['column', 'row']}>
                                            <Box flex={1} pr={[null, 4]}>
                                                <TextInputField
                                                    id="invoiceAddress.street"
                                                    placeholder={formatMessage({ defaultMessage: 'Straatnaam' })}
                                                    name="invoiceAddress.street"
                                                />
                                            </Box>
                                            <Box mt={[4, 0]} width={[1, '20rem']}>
                                                <TextInputField
                                                    maxLength={20}
                                                    placeholder={formatMessage({ defaultMessage: 'Huisnummer' })}
                                                    name="invoiceAddress.number"
                                                />
                                            </Box>
                                        </Flex>
                                        <Flex mt="2rem" flex={1} width={1} flexDirection={['column', 'row']}>
                                            <Box width={[1, '20rem']}>
                                                <TextInputField
                                                    maxLength={20}
                                                    id="invoiceAddress.postalCode"
                                                    placeholder={formatMessage({ defaultMessage: 'Postcode' })}
                                                    name="invoiceAddress.postalCode"
                                                />
                                            </Box>
                                            <Box flex={1} mt={[4, 0]} pl={[null, 4]}>
                                                <TextInputField
                                                    id="invoiceAddress.city"
                                                    placeholder={formatMessage({ defaultMessage: 'Plaats' })}
                                                    name="invoiceAddress.city"
                                                />
                                            </Box>
                                        </Flex>
                                        {countryOptions.length > 1 && (
                                            <Flex mt="2rem" flex={1} width={1} flexDirection={['column', 'row']}>
                                                <Box width={[1, 0.5]} style={{ maxWidth: '40rem' }}>
                                                    <SelectInput
                                                        id="invoiceAddress.countryCode"
                                                        name="invoiceAddress.countryCode"
                                                        isSearchable
                                                        placeholder={formatMessage({
                                                            defaultMessage: 'Selecteer land',
                                                        })}
                                                        options={countryOptions}
                                                    />
                                                </Box>
                                            </Flex>
                                        )}
                                    </>
                                )}
                            </Flex>
                        </FieldSet>
                    </Box>
                </FieldSet>
            </Box>

            <FieldSet>
                <LabelBox>
                    <Label htmlFor="vatNumber">
                        <FormattedMessage defaultMessage="BTW nummer" />
                        {brandConfig.registrationForm?.hasRequiredVatNumber && <RequiredStar />}
                    </Label>
                </LabelBox>
                <Box width={1}>
                    <TextInputField id="vatNumber" name="vatNumber" />
                </Box>
            </FieldSet>
        </>
    );
};

const PackageSection = () => {
    const { formatMessage } = useIntl();
    const linkUrl = formatMessage({ defaultMessage: 'https://www.campercontact.com/nl/content/subscriptieszakelijk' });
    return (
        <>
            <FieldHeading title={formatMessage({ defaultMessage: 'Campercontact' })}>
                <FormattedMessage defaultMessage="We horen graag hoe je met ons wilt samenwerken." />
                <br />
                <br />
                <Body variant="small">
                    <FormattedMessage
                        defaultMessage="We bieden op dit moment verschillende pakketten aan. Met ons Brons of Zilver pakket geniet je van vele voordelen. We hanteren tevens een lagere commissie per boeking bij deze pakketten. Op {link} lees je meer over onze pakketten."
                        values={{
                            link: (
                                <a href={linkUrl} target="_blank" rel="noreferrer">
                                    <FormattedMessage defaultMessage="deze pagina" />
                                </a>
                            ),
                        }}
                    />
                </Body>
            </FieldHeading>
            <FieldSet>
                <LabelBox>
                    <FormattedMessage defaultMessage="Pakket" />
                </LabelBox>

                <Stack width={1}>
                    <RadioField name="subscriptionPackage" id="Basic">
                        <FormattedMessage defaultMessage="Basis - 15% commissie per boeking." />
                    </RadioField>
                    <RadioField name="subscriptionPackage" id="Bronze">
                        <FormattedMessage defaultMessage="Brons - 199,- per jaar. 12% commissie per boeking." />
                    </RadioField>
                    <RadioField name="subscriptionPackage" id="Silver">
                        <FormattedMessage defaultMessage="Zilver - 299,- per jaar. 10% commissie per boeking." />
                    </RadioField>
                </Stack>
            </FieldSet>
        </>
    );
};

const ExtraInformation = () => {
    const { formatMessage } = useIntl();
    return (
        <>
            <FieldHeading title={formatMessage({ defaultMessage: 'Extra informatie' })} />

            <FieldSet>
                <LabelBox>
                    <Label htmlFor="comment">
                        <FormattedMessage defaultMessage="Opmerkingen" />
                    </Label>
                    <FieldSetHint>
                        <FormattedMessage defaultMessage="Vul hier eventuele aanvullende informatie in die relevant is voor deze registratie." />
                    </FieldSetHint>
                </LabelBox>
                <Box width={1}>
                    <TextInputField id="comment" name="comment" type="textarea" />
                </Box>
            </FieldSet>
        </>
    );
};

const RegistrationButton = styled(Button)`
    margin-top: 2rem;
    width: 100%;
`;

const ArrayRentalInputContainer = styled(Box)`
    > * + * {
        margin-top: 16px;
    }
`;

const Stack = styled(Box)`
    > * + * {
        margin-top: 16px;
    }
`;

const LogoContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
`;

const RequiredStar = styled.span`
    color: red;
    ::after {
        content: '*';
    }
`;

const ConditionalHiddenFieldSet = styled(FieldSet)<{ hidden: boolean }>`
    visibility: ${props => (props.hidden ? 'hidden' : 'visible')};
    position: ${props => (props.hidden ? 'absolute' : 'relative')};
    z-index: ${props => (props.hidden ? -1 : 1)};
`;

const StyledAccommodationContainer = styled(Box)`
    position: relative;
    background: ${({ theme }) => theme.colors.neutral[5]};
    padding: 32px;
    margin-bottom: 32px;
    border-radius: ${({ theme }) => theme.radius.button};
`;
export default Registration;
