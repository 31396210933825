import { MapLocationInputField } from '@oberoninternal/travelbase-ds/components/form/MapLocationInput';
import SelectInput from '@oberoninternal/travelbase-ds/components/form/SelectInput';
import { TextInputField } from '@oberoninternal/travelbase-ds/components/form/TextInput';
import { Label } from '@oberoninternal/travelbase-ds/components/primitive/Label';
import Pagehead from '@oberoninternal/travelbase-ds/components/section/Pagehead';
import { Box, Flex } from '@rebass/grid';
import gql from 'graphql-tag';
import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import brandConfig from '../../../../constants/brandConfig';
import { NotFoundError } from '../../../../entities/NotFoundError';
import { UnitParams } from '../../../../entities/UnitParams';
import { useEditLocationMutation, useLocationQuery } from '../../../../generated/graphql';
import useIsBackofficeuser from '../../../../hooks/useIsBackofficeuser';
import { getUnitTypeTranslation } from '../../../../utils/getUnitTypeTranslation';
import omitEqualValues from '../../../../utils/omitEqualValues';
import { locationValidation } from '../../../../utils/validations/locationValidation';
import FieldSet from '../../../atoms/FieldSet';
import FieldSetHint from '../../../atoms/FieldSetHint';
import MapLocationDetermineButton from '../../../atoms/MapLocationDetermineButton';
import FieldHeading from '../../../molecules/FieldHeading';
import LabelBox from '../../../molecules/LabelBox';
import TranslationInput from '../../../molecules/TranslationInput';
import FormScreen from '../../../organisms/FormScreen';
import Loading from '../../../organisms/Loading';

const locationFragment = gql`
    fragment Location on RentalUnit {
        id
        slug
        type
        accommodation {
            id
            name
            address {
                city
                street
                number
                postalCode
                countryCode
            }
            descriptionNL: descriptionLocation(locale: "nl")
            descriptionDE: descriptionLocation(locale: "de")
            descriptionEN: descriptionLocation(locale: "en")
            descriptionPending: descriptionLocationPending
            coordinates {
                lat
                lon
            }
        }
    }
`;

export const locationQuery = gql`
    query Location($unitSlug: String!) {
        rentalUnit(slug: $unitSlug) {
            ...Location
        }
    }
    ${locationFragment}
`;

export const editLocationMutation = gql`
    mutation EditLocation($input: EditLocationAndDistanceInput!) {
        editLocationAndDistance(input: $input) {
            rentalUnit {
                ...Location
            }
        }
    }
    ${locationFragment}
`;

const Location: FC<React.PropsWithChildren<UnitParams>> = ({ unitSlug, partnerId }) => {
    const { data, loading } = useLocationQuery({ variables: { unitSlug } });
    const [mutate] = useEditLocationMutation();
    const intl = useIntl();
    const { formatMessage } = intl;
    const isBackofficeUser = useIsBackofficeuser(partnerId);
    if (loading) {
        return <Loading />;
    }

    if (!data || !data.rentalUnit || !data.rentalUnit.accommodation) {
        throw new NotFoundError();
    }

    const {
        rentalUnit: {
            id,
            type,
            accommodation: {
                address,
                coordinates,
                descriptionNL,
                descriptionEN,
                descriptionDE,
                descriptionPending,
                name,
            },
        },
    } = data;
    const city = address?.city ?? '';
    const postalCode = address?.postalCode ?? '';
    const street = address?.street ?? '';
    const number = address?.number ?? '';
    const { countryOptions } = brandConfig;
    const countryCode = address?.countryCode
        ? address.countryCode
        : countryOptions.length === 1
        ? countryOptions[0].value
        : '';

    const initialValues = {
        coordinates: {
            latitude: coordinates?.lat ?? 0,
            longitude: coordinates?.lon ?? 0,
        },
        descriptionLocation: descriptionPending ?? descriptionNL ?? '',
        address: { city, postalCode, street, number, countryCode },
    };
    return (
        <FormScreen
            handleSubmit={async values => {
                const { coordinates: newCoordinates, ...rest } = omitEqualValues(values, initialValues, [
                    'descriptionLocation',
                ]);
                await mutate({
                    variables: {
                        input: {
                            rentalUnitId: id,
                            coordinates: {
                                lat: newCoordinates.latitude,
                                lon: newCoordinates.longitude,
                            },
                            ...rest,
                        },
                    },
                });
            }}
            validationSchema={locationValidation(intl)}
            initialValues={initialValues}
        >
            {() => (
                <>
                    <Pagehead
                        title={formatMessage({
                            defaultMessage: 'Ligging en afstanden',
                        })}
                    >
                        <FormattedMessage
                            defaultMessage="Hier beheer je de gegevens over de ligging van {name}"
                            values={{ name }}
                        />
                    </Pagehead>
                    <FieldHeading
                        title={formatMessage({
                            defaultMessage: 'Adres',
                        })}
                    >
                        <FormattedMessage defaultMessage="Dit adres wordt alleen getoond aan gasten en medewerkers van de VVV." />
                    </FieldHeading>
                    <FieldSet>
                        <LabelBox>
                            <Label htmlFor="street">
                                <FormattedMessage defaultMessage="Adres (straat en huisnummer)" />
                            </Label>
                        </LabelBox>
                        <Flex width={1} flexDirection={['column', 'row']}>
                            <Box flex={1} pr={[null, 4]}>
                                <TextInputField
                                    id="street"
                                    placeholder={formatMessage({
                                        defaultMessage: 'Adres',
                                    })}
                                    name="address.street"
                                />
                            </Box>
                            <Box mt={[4, 0]} width={[1, '20rem']}>
                                <TextInputField
                                    maxLength={20}
                                    placeholder={formatMessage({
                                        defaultMessage: 'Huisnummer',
                                    })}
                                    name="address.number"
                                />
                            </Box>
                        </Flex>
                    </FieldSet>

                    <FieldSet>
                        <LabelBox>
                            <Label htmlFor="postalCode">
                                <FormattedMessage defaultMessage="Postcode en plaats" />
                            </Label>
                        </LabelBox>
                        <Flex width={1} flexDirection={['column', 'row']}>
                            <Box width={[1, '20rem']}>
                                <TextInputField
                                    maxLength={20}
                                    id="postalCode"
                                    // placeholder="Postcode"
                                    placeholder={formatMessage({
                                        defaultMessage: 'Postcode',
                                    })}
                                    name="address.postalCode"
                                />
                            </Box>
                            <Box flex={1} mt={[4, 0]} pl={[null, 4]}>
                                <TextInputField
                                    id="city"
                                    placeholder={formatMessage({
                                        defaultMessage: 'Plaats',
                                    })}
                                    name="address.city"
                                />
                            </Box>
                        </Flex>
                    </FieldSet>
                    {countryOptions.length > 1 && (
                        <FieldSet>
                            <LabelBox>
                                <Label htmlFor="countryCode">
                                    <FormattedMessage defaultMessage="Land" />
                                </Label>
                            </LabelBox>
                            <Box width={[1, 0.5]} style={{ maxWidth: '40rem' }}>
                                <SelectInput
                                    id="countryCode"
                                    name="address.countryCode"
                                    isSearchable
                                    // placeholder="Selecteer land"
                                    placeholder={formatMessage({
                                        defaultMessage: 'Selecteer land',
                                    })}
                                    options={countryOptions}
                                />
                            </Box>
                        </FieldSet>
                    )}
                    <FieldSet>
                        <LabelBox>
                            <Label>
                                <FormattedMessage defaultMessage="Locatie (GPS)" />
                            </Label>
                            <FieldSetHint>
                                <FormattedMessage
                                    defaultMessage="Plaats de pin op de plek van je accommodatie die grenst aan de openbare weg (i.v.m.
                                navigatie). Met de knop kun je de locatie (GPS) laten bepalen aan de hand van het adres."
                                />
                            </FieldSetHint>
                        </LabelBox>
                        <Flex width={1} flexDirection="column">
                            <MapLocationInputField
                                mapBoxAccessToken={process.env.REACT_APP_MAPBOX_KEY ?? ''}
                                name="coordinates"
                            >
                                <MapLocationDetermineButton />
                            </MapLocationInputField>
                        </Flex>
                    </FieldSet>
                    {(!brandConfig.hasCheckoutOnly || isBackofficeUser) && (
                        <>
                            <FieldHeading
                                title={formatMessage({
                                    defaultMessage: 'Ligging',
                                })}
                            >
                                <FormattedMessage
                                    defaultMessage="Geef hier de beschrijving van de ligging en sfeer van {name}. Denk ligging ten opzichte van nabijgelegen dorp(en), bezienswaardigheden, natuur en strand. Probeer de sfeer van je accommodatie op te roepen met woorden als vrijstaand, comfortabel, rustig gelegen. Als je meerdere accommodatietypes op dezelfde locatie verhuurt, wordt deze tekst op de website van de VVV bij iedere {type} getoond."
                                    values={{ name, type: getUnitTypeTranslation(type)?.toLowerCase() }}
                                />
                            </FieldHeading>
                            <TranslationInput
                                name="descriptionLocation"
                                placeholder={formatMessage({
                                    defaultMessage: 'Omschrijving van de ligging',
                                })}
                                label={formatMessage({
                                    defaultMessage: 'Tekst invoeren of aanpassen',
                                })}
                                hint={formatMessage({
                                    defaultMessage:
                                        'Plaats hier de tekst(aanpassing) die je op de website van de VVV wilt tonen. Wij verzorgen de plaatsing en vertaling binnen enkele dagen.',
                                })}
                                data={{
                                    en: descriptionEN,
                                    nl: descriptionNL,
                                    de: descriptionDE,
                                    pending: descriptionPending,
                                }}
                            />
                        </>
                    )}
                </>
            )}
        </FormScreen>
    );
};

export default Location;
